<template>
  <div ref="body">
    <custom-header active="design" :subActive="current"></custom-header>
    
    <div class="content-container">
      <!-- <h1>深圳家居设计周</h1> -->
      <!-- <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/01.jpg" alt="" class="block" >
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/02.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/03.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/04.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/05.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/06.jpg" alt="" class="block"> -->
      
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/pc_01.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/pc_02.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/pc_03.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/pc_04.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/pc_05.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/pc_06.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/pc_07.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/pc_08.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/pc_09.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/pc_10.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/pc_11.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/pc_12.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/pc_13.jpg" alt="" class="block">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/competition/pc_14.jpg" alt="" class="block">
      
    </div>
    
    <!-- <a href="https://zq.3vjia.com/s.php?S3OXXH" target="_blank" rel="noopener noreferrer"></a> -->
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    // title: 'My Example App', // set a title
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      current: 'competition',
    }
  },
  created() {

  },
  methods: {

  },
}
</script>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 375px;
    margin: 0 auto;
    
    img {
      width: 100%;
      height: auto;
    }
  }
  
}

@media screen and (min-width: 1024px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 280px;
    margin: 10px auto 20px;
    
    img {
      width: 240px;
      height: auto;
      margin: 0 auto;
    }
  }
  
}
</style>